import { Route, Routes, useNavigate } from "react-router-dom";
import Task from "./Task/index";
import CheckList from "./Checklist/index";
import Glossary from "./Glossary/index";
import Tutorials from "./Tutorials/index";
import Courses from "./Courses/index";
import ContentRequestTemplates from "./ContentRequestTemplates/index";
import ContentRequestItems from "./ContentRequestItems/index";
import FormTemplates from "./FormTemplates/index";
import FormSections from "./FormSections/index";
import FormFields from "./FormFields/index";
import FormFieldOptions from "./FormFieldOptions/index";
import ScreenRecordings from "./ScreenRecordings/index";
import Services from "./Services/index";
import ProposalTemplates from "./ProposalTemplates/index";
import ContractTemplates from "./ContractTemplates/index";
import { InvalidURL } from "commonUI";
import Notes from "./Notes";
import { useUserPermissions } from "hooks/user";
import { useEffect } from "react";

export default function Library() {
  const { add_items_from_template_library } = useUserPermissions();
  const navigate = useNavigate();

  useEffect(() => {
    if (add_items_from_template_library === undefined) return;
    if (
      !add_items_from_template_library &&
      window.location.pathname.startsWith("/site-settings/library-item/")
    ) {
      navigate("/site-settings/interface");
    }
  }, [add_items_from_template_library]);
  return (
    <>
      <Routes>
        <Route exact path={`/`}>
          <Route exact index element={<Task />} />
          <Route exact path={`/tasks`}>
            <Route exact index element={<Task />} />
          </Route>
          <Route exact path={`/checklists`}>
            <Route exact index element={<CheckList />} />
          </Route>
        </Route>
        <Route exact path={`/glossary`}>
          <Route exact index element={<Glossary />} />
        </Route>
        <Route exact path={`/tutorials`}>
          <Route exact index element={<Tutorials />} />
        </Route>
        <Route exact path={`/courses`}>
          <Route exact index element={<Courses />} />
        </Route>
        <Route exact path={`/content-request-templates`}>
          <Route exact index element={<ContentRequestTemplates />} />
        </Route>
        <Route exact path={`/content-request-items`}>
          <Route exact index element={<ContentRequestItems />} />
        </Route>
        <Route exact path={`/services`}>
          <Route exact index element={<Services />} />
        </Route>
        <Route exact path={`/proposal-template`}>
          <Route exact index element={<ProposalTemplates />} />
        </Route>
        <Route exact path={`/contract-template`}>
          <Route exact index element={<ContractTemplates />} />
        </Route>
        <Route exact path={`/form-templates`}>
          <Route exact index element={<FormTemplates />} />
        </Route>
        <Route exact path={`/form-sections`}>
          <Route exact index element={<FormSections />} />
        </Route>
        <Route exact path={`/form-fields`}>
          <Route exact index element={<FormFields />} />
        </Route>
        <Route exact path={`/form-field-options`}>
          <Route exact index element={<FormFieldOptions />} />
        </Route>
        <Route exact path={`/screen-recordings`}>
          <Route exact index element={<ScreenRecordings />} />
        </Route>
        <Route exact path={`/notes`}>
          <Route exact index element={<Notes />} />
        </Route>
        <Route path={`/*`} element={<InvalidURL />} />
      </Routes>
    </>
  );
}
