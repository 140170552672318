import { useParams } from "react-router-dom";
import {
  BackArrow,
  SaveIcon,
  PluginIcon,
  ImportIcon,
  AddIcon,
  CloudDownloadIcon,
} from "allIcons";
import {
  HeaderContainer,
  IconWrapper,
  PrimaryHeading,
  RightMainContainer,
  MainButton,
  BodyContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  AddComponent,
  SecondaryHeading,
  PreviewCard,
  PopOver,
  PopUpListing,
  AddFromTemplateLibrary,
  DropDownCard,
  Loader,
  DeleteButton,
} from "commonUI";
import { useEffect, useMemo, useState } from "react";
import { useAddPlugin, usePlugins } from "api/templateLibrary/plugins";
import {
  useAddPluginList,
  useDeletePluginList,
  usePluginList,
  useUpdatePluginList,
} from "api/templateLibrary/pluginList";
import usePluginSearch from "../Plugins/usePluginSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getUserSavePermission,
  isEmpty,
  isEqual,
  parse,
  useUtilities,
} from "helpers";
import { useTags } from "api/siteSettings/Tags";
import { useUserPermissions } from "hooks/user";
import { useUploadFiles } from "api/util";
import { ApiResponseType } from "enums";

export default function AddEditPluginList() {
  const { id } = useParams();
  const [inputValues, setInputValues] = useState({
    list_name: "",
    plugin_list: [],
    tags: [],
  });
  const [addPluginPopup, setAddPluginPopUp] = useState(false);
  const [importTemplateLibrary, setImportTemplateLibrary] = useState(false);
  const [importWordpress, setImportWordpress] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState("top");
  const [taskSearchText, setTaskSearchText] = useState("");
  const [openTags, setOpenTags] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [query, setQuery] = useState("");
  const {
    user: { userData },
    notification,
    navigate,
  } = useUtilities();

  const { mutateAsync: uploadFiles, isLoading: uploadingPlugin } =
    useUploadFiles();
  const { mutateAsync: addPlugin, isLoading: addingPlugin } = useAddPlugin(
    "",
    false
  );
  const { data: plugins } = usePlugins();
  const { data: tags } = useTags();
  const {
    loading,
    error: searchError,
    hasMore,
    plugins: wordpressPlugins,
  } = usePluginSearch(query, pageNumber);
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const { data: pluginList, isLoading: pluginListLoading } = usePluginList();

  useEffect(() => {
    if (pluginListLoading || id === "add") return;
    const pluginData = pluginList.filter(
      (ele) => Number(ele.id) === Number(id)
    )[0];
    if (isEmpty(pluginData ?? {})) {
      navigate("/template-library/plugin-lists");
      return;
    }
    setInputValues({
      list_name: pluginData.list_name ?? "",
      plugin_list: parse(pluginData.plugin_list) ?? [],
      tags: parse(pluginData.tags) ?? [],
    });
  }, [id, pluginList, pluginListLoading]);

  const { mutate: addPluginList, isLoading: addPluginListLoading } =
    useAddPluginList();
  const { mutate: updatePluginList, isLoading: isUpdating } =
    useUpdatePluginList();

  const handleSave = async () => {
    if (!inputValues.list_name) {
      notification({
        type: "error",
        message: "Please enter list name",
      });
      return;
    }
    const tempInput = { ...inputValues };
    let unUploadedPlugins = inputValues.plugin_list.filter((ele) => !ele.id);
    let uploadedPlugins = [];
    if (unUploadedPlugins.length > 0) {
      const zipUploads = unUploadedPlugins.filter(
        (ele) => ele.uploadedPluginFile
      );
      let normalUploads = unUploadedPlugins.filter(
        (ele) => !ele.uploadedPluginFile
      );

      // upload zip files and prepare data for add plugin api
      if (zipUploads.length > 0) {
        let uploadResults = await Promise.allSettled(
          zipUploads.map((ele) => {
            return uploadFiles({ product_logo: ele.uploadedPluginFile }).then(
              (res) => {
                if (res.Data) {
                  return {
                    plugin_title: res["File Name"],
                    plugin_slug: res["Data"],
                    plugin_icon: null,
                    added_by: userData.id,
                    plugin_type: 1,
                  };
                }
              }
            );
          })
        );

        if (uploadResults.some((ele) => ele.status === "rejected")) {
          notification({
            type: "error",
            message: "Some files could not be uploaded",
          });
        }

        normalUploads = [
          ...normalUploads,
          ...uploadResults
            .filter((ele) => ele.status === "fulfilled")
            .map((ele) => ele.value),
        ];
      }

      // add new plugins to db
      if (normalUploads.length > 0) {
        uploadedPlugins = await Promise.allSettled(
          normalUploads.map((ele) => {
            return addPlugin(ele);
          })
        );

        if (uploadedPlugins.some((ele) => ele.status === "rejected")) {
          notification({
            type: "error",
            message: "Some plugins could not be added",
          });
        }

        uploadedPlugins = uploadedPlugins
          .filter(
            (_) =>
              _.status === "fulfilled" &&
              _.value.StatusCode === ApiResponseType.SUCCESS
          )
          .map((_) => _.value.Data);
      }
    }
    tempInput.plugin_list = [
      ...uploadedPlugins,
      ...inputValues.plugin_list.filter((ele) => ele.id),
    ];

    if (id === "add") {
      addPluginList({ ...tempInput });
      return;
    }
    updatePluginList({ ...tempInput, pluginlist_id: id });
  };

  const { mutate: deletePluginList, isLoading: isDeleting } =
    useDeletePluginList();

  const onUploadPlugin = (e) => {
    const file = e.target.files[0];
    if (file.type.includes("zip")) {
      setInputValues((pre) => ({
        ...pre,
        plugin_list: [
          ...pre.plugin_list,
          {
            plugin_title: file.name.replace(".zip", ""),
            plugin_icon: null,
            plugin_slug: null,
            added_by: userData.id,
            plugin_type: 1,
            uploadedPluginFile: file,
          },
        ],
      }));
    } else {
      notification({
        type: "error",
        message: "Invalid file type, please upload a .zip file.",
      });
    }
    e.target.value = "";
    e.target.files = null;
  };

  return (
    <>
      <RightMainContainer>
        {addPluginListLoading ||
        isUpdating ||
        isDeleting ||
        uploadingPlugin ||
        addingPlugin ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() => navigate("/template-library/plugin-lists")}
            />
            <PrimaryHeading className="clamp-text">
              {id === "add"
                ? "Add Plugin List"
                : inputValues.list_name ?? "[Plugin List Name]"}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deletePluginList(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            {getUserSavePermission({id, addPermission: add_new_templates, editPermission: edit_templates}) ? (
              <MainButton onClick={handleSave}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={inputValues.list_name}
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, list_name: value }))
            }
          />
          {inputValues.plugin_list.map((plugin, index) => {
            return (
              <ListCard
                data={plugin.plugin_title}
                editIcon={Boolean(plugin.id)}
                editLink={`/template-library/plugins/${plugin.id}`}
                icon={
                  <ListIconWrapper
                    className={plugin.plugin_icon ? "bg-white" : ""}
                  >
                    {plugin.plugin_icon ? (
                      <img
                        src={plugin.plugin_icon}
                        alt={plugin.plugin_title}
                        className="avatar"
                      />
                    ) : (
                      <PluginIcon />
                    )}
                  </ListIconWrapper>
                }
                deleteIcon={true}
                crossIcon
                showModalonCross
                onCross={() => {
                  setInputValues((pre) => {
                    const newPluginList = pre.plugin_list.filter(
                      (_, i) => i !== index
                    );
                    return { ...pre, plugin_list: newPluginList };
                  });
                }}
                deleteTitle="Remove This Item?"
                deleteButtonText="Confirm"
                deleteMessage="You are about to remove this item from your template. Are you sure you want to do this?"
              />
            );
          })}
          <AddComponent
            title="Add Plugin"
            onClick={(event) => {
              setPopoverPosition(event.pageY < 600 ? "top" : "bottom");
              setAddPluginPopUp(!addPluginPopup);
            }}
            value={addPluginPopup}
          >
            <PopOver
              toolTip={{ vertical: popoverPosition, horizontal: "center" }}
              open={importTemplateLibrary || importWordpress}
              onClose={() => {
                setImportTemplateLibrary(false);
                setImportWordpress(false);
                setAddPluginPopUp(true);
              }}
              style={{
                top: popoverPosition === "top" ? "calc(100% - 10px)" : "auto",
                right: "auto",
                bottom:
                  popoverPosition === "top" ? "auto" : "calc(100% + 15px)",
              }}
            >
              <InfiniteScroll
                dataLength={plugins.length}
                next={() => {
                  setPageNumber((prev) => prev + 1);
                }}
                hasMore={hasMore}
                scrollableTarget="templateScrollableDiv"
                scrollThreshold={0.7}
                endMessage={
                  !loading &&
                  !hasMore &&
                  plugins.length > 0 && (
                    <p className="body-text" style={{ textAlign: "center" }}>
                      <b>No more Plugins</b>
                    </p>
                  )
                }
              >
                <AddFromTemplateLibrary
                  id="templateScrollableDiv"
                  autoSearch={false}
                  onSearchValueChange={(value) => {
                    setQuery(value);
                    setPageNumber(1);
                  }}
                  heading={
                    importWordpress
                      ? "Add From WordPress Repository"
                      : "Add From Template Library"
                  }
                  data={
                    importWordpress
                      ? wordpressPlugins
                          .filter(
                            (ele) =>
                              !inputValues.plugin_list.some((e) =>
                                isEqual(e.plugin_slug, ele.slug)
                              )
                          )
                          .map((ele) => ({
                            title: ele.name,
                            icon: !isEmpty(ele.icons) ? (
                              <img
                                src={Object.values(ele.icons)[0]}
                                alt="plugin icon"
                                className="avatar"
                              />
                            ) : (
                              <PluginIcon />
                            ),
                            iconWrapperClass: !isEmpty(ele.icons)
                              ? "bg-white"
                              : "",
                            onClick: () => {
                              if (
                                inputValues.plugin_list?.filter(
                                  (_) => _.plugin_slug === ele.slug
                                ).length === 0
                              ) {
                                let pluginIndex = plugins.findIndex(
                                  (_) => _.plugin_slug === ele.slug
                                );
                                if (pluginIndex !== -1) {
                                  setInputValues((pre) => ({
                                    ...pre,
                                    plugin_list: [
                                      ...pre.plugin_list,
                                      plugins[pluginIndex],
                                    ],
                                  }));
                                  return;
                                }
                                setInputValues((pre) => ({
                                  ...pre,
                                  plugin_list: [
                                    ...pre.plugin_list,
                                    {
                                      plugin_title: ele.name,
                                      plugin_slug: ele.slug,
                                      plugin_icon: Object.values(ele.icons)[0],
                                      added_by: userData.id,
                                      plugin_type: 0,
                                    },
                                  ],
                                }));
                              }
                            },
                          }))
                      : plugins
                          .filter(
                            (ele) =>
                              !inputValues.plugin_list.some((e) =>
                                isEqual(ele.id, e.id)
                              )
                          )
                          .map((ele) => ({
                            title: ele.plugin_title,
                            icon: ele.plugin_icon ? (
                              <img src={ele.plugin_icon} className="avatar" />
                            ) : (
                              <PluginIcon />
                            ),
                            iconWrapperClass: ele.plugin_icon ? "bg-white" : "",
                            onClick: () =>
                              setInputValues((pre) => {
                                if (
                                  pre.plugin_list?.filter(
                                    (_) => _.id === ele.id
                                  ).length > 0
                                )
                                  return pre;
                                return {
                                  ...pre,
                                  plugin_list: [...pre.plugin_list, ele],
                                };
                              }),
                          }))
                  }
                  searchText={taskSearchText}
                  setSearchText={(value) => setTaskSearchText(value)}
                />
              </InfiniteScroll>
            </PopOver>
            <PopOver
              open={addPluginPopup}
              toolTip={{ vertical: popoverPosition, horizontal: "center" }}
              onClose={setAddPluginPopUp}
              style={{
                top: popoverPosition === "top" ? "calc(100% - 10px)" : "auto",
                right: "auto",
                bottom:
                  popoverPosition === "top" ? "auto" : "calc(100% + 15px)",
              }}
            >
              <PopUpListing
                data={[
                  {
                    title: "From Template Library",
                    icon: <CloudDownloadIcon />,
                    onClick: () => {
                      setImportTemplateLibrary(true);
                      setImportWordpress(false);
                      setAddPluginPopUp(false);
                    },
                    arrow: true,
                  },
                  {
                    title: "From WordPress Repo",
                    icon: <AddIcon />,
                    onClick: () => {
                      setImportTemplateLibrary(false);
                      setImportWordpress(true);
                      setAddPluginPopUp(false);
                      setPageNumber(1);
                    },
                    arrow: true,
                  },
                  {
                    title: "Upload Plugin .zip File",
                    icon: <ImportIcon />,
                    onClick: () => {
                      setAddPluginPopUp(false);
                    },
                    htmlFor: "plugin-list-input",
                  },
                ]}
              />
              <input
                type="file"
                hidden
                id="plugin-list-input"
                accept=".zip,.rar,.7zip"
                onChange={onUploadPlugin}
              />
            </PopOver>
          </AddComponent>
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((tag) => (
                <PreviewCard
                  color={tag.color}
                  title={tag.text}
                  icon={true}
                  className="m-0"
                  onCross={() => {
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter((ele) => ele.id !== tag.id),
                    }));
                  }}
                />
              ))}
            </div>
            <AddComponent
              title="Add Tag"
              value={openTags}
              onClick={() => setOpenTags(true)}
            >
              <PopOver
                open={openTags}
                onClose={() => setOpenTags(false)}
                toolTip={{ vertical: "bottom", horizontal: "center" }}
                style={{
                  bottom: "calc(100% + 15px)",
                  top: "auto",
                  right: "auto",
                  left: "auto",
                }}
              >
                <DropDownCard
                  title="Tags"
                  listStyle={true}
                  className="relative-important"
                  selectedValues={inputValues.tags.map((item) => item.id)}
                  list={tags.map((ele) => ({
                    ...ele,
                    index: ele.id,
                    text: ele.tag_title,
                  }))}
                  onSelect={(item) => {
                    if (
                      inputValues.tags.find(
                        (i) => Number(i.id) === Number(item.id)
                      )
                    ) {
                      return;
                    }
                    setInputValues((pre) => ({
                      ...pre,
                      tags: [
                        ...pre.tags,
                        {
                          id: item.id,
                          text: item.tag_title,
                          color: item.color,
                        },
                      ],
                    }));
                  }}
                />
              </PopOver>
            </AddComponent>
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
