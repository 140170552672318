import {
  addPriority,
  deletePriority,
  getAllPriorities,
  updatePriority,
} from "api/siteSettings/prioirty";
import { useMutation, useQuery } from "@tanstack/react-query";
import { isEqual, printError, useSearch, useUtilities } from "helpers";
import { useEffect } from "react";
import {
  addSinglePriority,
  deleteSinglePriority,
  getPriorities,
  updateSinglePriority,
} from "redux/reducers/siteSettings/priorityReducer";
import { ApiResponseType, NotificationType } from "enums";

function useGetPriority() {
  const { web = null } = useSearch();
  return useQuery(["priorityList", web], () => getAllPriorities(web));
}

export function usePriority() {
  const { web = null } = useSearch();
  const { data, isLoading, error } = useGetPriority();
  const {
    dispatch,
    priorities: { priorities, loaded, website_id },
  } = useUtilities();

  useEffect(() => {
    if (data && (website_id !== web || !loaded)) {
      dispatch(
        getPriorities({
          priorities: data.Data,
          website_id: web,
        })
      );
    }
  }, [isLoading, loaded, data, web, website_id]);

  return {
    data: priorities,
    isLoading: isLoading || !loaded,
    error,
  };
}

export function useDeletePriority(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deletePriority, {
    onSuccess: (data) => {
      if (isEqual(data.StatusCode, ApiResponseType.SUCCESS)) {
        dispatch(deleteSinglePriority({ id: data.id }));
        notification({
          type: "success",
          message: "Priority deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: printError(data.Message),
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: printError(err.message),
      });
    },
  });
}

export function useUpdatePriority() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updatePriority, {
    onSuccess: (data) => {
      if (isEqual(data.StatusCode, ApiResponseType.SUCCESS)) {
        dispatch(updateSinglePriority(data.Data));
        notification({
          type: NotificationType.SUCCESS,
          message: "Priority updated Successfully",
        });
      } else {
        notification({
          type: NotificationType.ERROR,
          message: printError(data.Message),
        });
      }
    },
    onError: (error) => {
      console.log(error);
      notification({
        type: "error",
        message: "Something went wrong, please try again later",
      });
    },
  });
}

export function useAddPriority(redirect = "") {
  const { web = null } = useSearch();
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(addPriority, {
    onSuccess: (data) => {
      if (!isEqual(data.StatusCode, ApiResponseType.SUCCESS)) {
        notification({
          type: "error",
          message: printError(data.Message),
        });
        return;
      }
      dispatch(addSinglePriority(data.Data));
      if (redirect)
        navigate(`${redirect}${data.Data.id}${web ? `?web=${web}` : ""}`);
      notification({
        type: "success",
        message: "Priority Added Successfully",
      });
    },
    onError: (error) => {
      notification({
        type: "error",
        message: printError(error.message),
      });
    },
  });
}
