import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  AddComponent,
  SelectField,
  MainButton,
  PopOver,
  DropDownCard,
  Loader,
  DeleteButton,
} from "commonUI";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserSavePermission, isEmpty, parse, useUtilities } from "helpers";
import { useTags } from "api/siteSettings/Tags";
import {
  useAddContentRequestItem,
  useContentRequestsItem,
  useDeleteContentRequestItem,
  useUpdateContentRequestItem,
} from "api/templateLibrary/contentRequestsItem";
import { useUserPermissions } from "hooks/user";

export default function AddEditContentRequestItem() {
  const { search } = useLocation();
  const { data: contentRequestsItem, isLoading: loadingContentRequestItem } =
    useContentRequestsItem();
  const { mutate: deleteContentRequestItem, isLoading: isDeleting } =
    useDeleteContentRequestItem(true);
  const {
    user: { userData },
    notification,
    navigate,
  } = useUtilities();
  const { mutate: addContent, isLoading: isAdding } =
    useAddContentRequestItem();
  const { mutate: updateContent, isLoading: isUpdating } =
    useUpdateContentRequestItem();
  const { id } = useParams();
  const [openAddTagModal, setOpenAddTagModal] = useState(false);
  const { data: tagList } = useTags();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const [inputValues, setInputValues] = useState({
    title: "",
    hint: "Text",
    instructions: "",
    tags: [],
  });

  useEffect(() => {
    if (
      id === "add" ||
      loadingContentRequestItem ||
      contentRequestsItem.length === 0
    )
      return;
    const item = contentRequestsItem.filter(
      (_) => String(_.id) === String(id)
    )[0];
    if (isEmpty(item)) return;
    setInputValues({
      title: item.title,
      hint: item.hint || item.type,
      instructions: item.instructions || item.description,
      tags: parse(item.tags),
    });
  }, [id, contentRequestsItem, loadingContentRequestItem]);

  function hanldeSave() {
    if (inputValues.title === "") {
      notification({
        type: "error",
        message: "Please enter title",
      });
      return;
    }

    if (!inputValues.hint) {
      notification({
        type: "error",
        message: "Please select content type",
      });
      return;
    }

    if (id === "add" && add_new_templates) {
      addContent({
        ...inputValues,
        requested_from: {
          user_avatar:
            "http://0.gravatar.com/avatar/c2b06ae950033b392998ada50767b50e?s=96&#038;d=mm&#038;r=g",
          name: userData?.name,
          role: "Client",
          group: "Admin",
          id: userData?.id,
        },
      });
      return;
    }
    if (edit_templates)
      updateContent({
        ...inputValues,
        id,
      });
  }

  return (
    <>
      <RightMainContainer>
        {isAdding || isUpdating || isDeleting ? <Loader loader="block" /> : ""}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() =>
                navigate(
                  search.split("=")[1] ??
                    "/template-library/content-request-items"
                )
              }
            />
            <PrimaryHeading>
              {id === "add" ? "Add Content Request Item" : inputValues.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deleteContentRequestItem(id)}
              deleteMessage="You are about to delete Content Request Item from your account. This
                action cannot be undone! All settings related to the WP Site
                Launcher Content Request Item will permanently removed. Do you wish to
                proceed and delete the Content Request Item?"
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={hanldeSave}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={inputValues.title}
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, title: value }))
            }
          />
          <TextEditor
            label="Instructions"
            editorClass="mt-2"
            option={false}
            rows={5}
            value={inputValues.instructions}
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, instructions: value }))
            }
          />
          <div className="mt-3">
            <SecondaryHeading>Content Type</SecondaryHeading>
            <SelectField
              value={inputValues.hint}
              setValue={(value) =>
                setInputValues((pre) => ({ ...pre, hint: value }))
              }
            >
              <option value="Text">Text</option>
              <option value="Image">Image</option>
              <option value="Video">Video</option>
              <option value="Link">Link</option>
              <option value="File">File</option>
            </SelectField>
          </div>
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((ele) => (
                <PreviewCard
                  icon
                  color={ele.color}
                  title={ele.text}
                  className="m-0"
                  onCross={() =>
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter(
                        (_) => String(_.id) !== String(ele.id)
                      ),
                    }))
                  }
                />
              ))}
            </div>
          </div>
          <AddComponent
            value={openAddTagModal}
            onClick={() => setOpenAddTagModal(true)}
          >
            <PopOver
              open={openAddTagModal}
              onClose={() => setOpenAddTagModal(false)}
              toolTip={{ vertical: "bottom", horizontal: "center" }}
              style={{
                top: "unset",
                left: "unset",
                right: "unset",
                bottom: "calc(100% + 15px)",
              }}
            >
              <DropDownCard
                title="Tags"
                selectedValues={inputValues.tags.map((ele) => ele.id)}
                listStyle
                list={tagList.map((ele) => ({
                  ...ele,
                  index: ele.id,
                  text: ele.tag_title,
                }))}
                onSelect={(value) =>
                  setInputValues((pre) => ({
                    ...pre,
                    tags: [
                      ...pre.tags,
                      {
                        id: value.id,
                        text: value.tag_title,
                        color: value.color,
                      },
                    ],
                  }))
                }
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
