import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  priorities: [],
  loaded: false,
  website_id: null,
};

export const prioritiesSlice = createSlice({
  name: "priorities",
  initialState,
  reducers: {
    getPriorities: (state, action) => {
      const { priorities, website_id } = action.payload;
      state.priorities = priorities;
      state.loaded = true;
      state.website_id = website_id;
    },
    addSinglePriority: (state, action) => {
      state.priorities.push(action.payload);
    },
    updateSinglePriority: (state, action) => {
      state.priorities = state.priorities.map((priority) => {
        if (isEqual(priority.id, action.payload.priority_id)) {
          return {
            ...priority,
            priority_title: action.payload.priority_title,
            color: action.payload.color,
          };
        }
        return { ...priority };
      });
    },
    deleteSinglePriority: (state, action) => {
      console.log(action.payload);
      state.priorities = state.priorities.filter(
        (priority) => !isEqual(priority.id, action.payload.id)
      );
    },
  },
});

export const {
  getPriorities,
  updateSinglePriority,
  addSinglePriority,
  deleteSinglePriority,
} = prioritiesSlice.actions;

export default prioritiesSlice.reducer;
