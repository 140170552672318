import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  IconWrapper,
  InputField,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  Loader,
  DeleteButton,
  LoadingCard,
  SecondaryHeading,
} from "commonUI";
import { useDefaultRoles, useRoles } from "api/usersModule/roles";
import { isEqual, objectValues, parse, useSearch, useUtilities } from "helpers";
import { useCompanies } from "api/usersModule/companies";
import {
  useAddMember,
  useAddUsertoWP,
  useDefaultMembers,
  useDeleteMember,
  useMembers,
  useSiteRelateUsers,
  useUpdateMember,
} from "api/usersModule/members";
import { USERTYPE } from "usersModule/enums";
import { useUserPermissions } from "hooks/user";
import { useUserLimitReached } from "hooks/plan";
import { useSites } from "api/sites";
import userPic from "assets/pic.png";
import { NotificationType } from "enums";
import "./member.css";
import { useUploadFiles } from "api/util";
export default function AddEditMember({ isSiteModule = false }) {
  const { pathname } = useLocation();
  const { id, "*": path } = useParams();
  const modulePath = path.split("/")[0];
  const { web = null, backurl } = useSearch();
  const { notification, navigate } = useUtilities();
  const { data } = useSites();
  const siteDetails = data?.site_details?.total_sites?.find((site) =>
    isEqual(site.dl_website_id, web)
  );
  const [inputValue, setInputValue] = useState({
    name: "",
    last_name: "",
    email: "",
    user_type: USERTYPE[modulePath] || USERTYPE.TEAM_MEMBER,
    user_role: "",
    wp_user_role: "subscriber",
    user_associated_company: "",
    website_id: "",
    system_status: 0,
    user_avatar: "",
  });

  const [avatarInputFile, setAvatarInputFile] = useState(null);

  const handleInputChange = (key, value) => {
    setInputValue((pre) => ({ ...pre, [key]: value }));
  };
  const { data: roles, isLoading: loadingRoles } = useRoles();
  const { data: defaultRoles, isLoading: loadingDefaultRoles } =
    useDefaultRoles();
  const { data: companies, isLoading: loadingCompanies } = useCompanies();
  const { data: members, isLoading: loadingMembers } = useMembers();
  const { data: defaultMembers, isLoading: loadingDefaultMembers } =
    useDefaultMembers();
  const { data: siteUsers, isLoading: loadingSiteUsers } = useSiteRelateUsers();
  const { isLoading, isLimitReached } = useUserLimitReached();
  const { mutateAsync: addMember, isLoading: addingMember } = useAddMember(
    isSiteModule ? "/site-settings/users" : "/users/" + modulePath,
    USERTYPE[modulePath]
  );
  const { mutateAsync: uploadFiles, isLoading: uploadingFile } =
    useUploadFiles();

  const { mutate: deleteMember, isLoading: deletingMember } = useDeleteMember(
    isSiteModule
      ? `/site-settings/users${web ? `?web=${web}` : ""}`
      : "/users/" + modulePath,
    USERTYPE[modulePath]
  );
  const { mutate: updateMember, isLoading: updatingMember } = useUpdateMember(
    isSiteModule ? "User" : USERTYPE[modulePath]
  );
  const { mutateAsync: addUsertoWP, isLoading: addingUserToWP } =
    useAddUsertoWP();

  const userAvatarFileInput = useRef();

  const {
    app_users_add_new_users,
    app_users_delete_users,
    app_users_edit_users,
  } = useUserPermissions();

  const userRoles = {};
  (web ? defaultRoles : roles)
    .filter((_) => _)
    .map((role) => {
      if (isEqual(role.nick, "owner")) return;
      userRoles[role.user_role] = {
        ...userRoles[role.user_role],
        [role.role_title]: {
          id: role.id,
          website_id: role.website_id,
        },
      };
    });

  useEffect(() => {
    if (
      loadingMembers ||
      isEqual(id, "add") ||
      !members ||
      loadingDefaultMembers ||
      !defaultMembers ||
      !siteUsers ||
      loadingSiteUsers
    )
      return;
    let teamMember = null;
    if (web) {
      teamMember = siteUsers.find((teamMember) => isEqual(teamMember.id, id));
    } else {
      teamMember = [...members, ...defaultMembers].find((teamMember) =>
        isEqual(teamMember.id, id)
      );
    }
    if (!teamMember) return;
    setInputValue({
      ...teamMember,
      user_avatar: teamMember?.user_avatar ?? "",
    });
  }, [
    members,
    loadingMembers,
    loadingDefaultMembers,
    defaultMembers,
    web,
    siteUsers,
    loadingSiteUsers,
  ]);

  async function handleSubmit() {
    if (inputValue.name === "") {
      notification({
        type: "error",
        message: "Please enter first name",
      });
      return;
    }
    if (inputValue.last_name === "") {
      notification({
        type: "error",
        message: "Please enter last name",
      });
      return;
    }
    if (inputValue.user_role === "") {
      notification({
        type: "error",
        message: "Please select user role",
      });
      return;
    }
    if (inputValue.email === "") {
      notification({
        type: "error",
        message: "Please enter user email",
      });
      return;
    }
    if (
      inputValue.email !== "" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue.email)
    ) {
      notification({
        type: "error",
        message: "Please enter valid email",
      });
      return;
    }
    let avatarUploadURL = null;
    if (avatarInputFile) {
      await uploadFiles({
        product_logo: avatarInputFile,
      }).then((res) => {
        avatarUploadURL = res.Data;
      });
    }

    if (isEqual(id, "add")) {
      addMember({
        data: {
          ...inputValue,
          website_id: web ? [web] : null,
          default_tem: isSiteModule && !web ? "1" : "0",
          user_avatar: avatarUploadURL ?? inputValue.user_avatar,
        },
      }).then((res) => {
        if (isSiteModule && web && res.StatusCode === 200) {
          addUsertoWP({
            web_url: siteDetails.web_url,
            email: res.Data.email,
            first_name: res.Data.name,
            last_name: res.Data.last_name,
            wpsl_user_id: res.Data.id,
            wp_user_role: res.Data.wp_user_role,
            "HTTP-DL-WEBSITE-KEY": siteDetails.dl_website_id,
          });
        }
      });
    } else {
      const tempRole = [...defaultRoles, ...roles].find((role) =>
        isEqual(role.id, inputValue.user_role)
      );
      updateMember({
        ...inputValue,
        user_id: id,
        single_site_id: web,
        roles: tempRole ? tempRole : inputValue.roles,
        user_avatar: avatarUploadURL ?? inputValue.user_avatar,
      });
    }
    setAvatarInputFile(null);
    if (avatarUploadURL) {
      setInputValue((pre) => ({ ...pre, user_avatar: avatarUploadURL }));
    }
  }

  const handleUploadAvatar = (event) => {
    const inputFile = event.target.files[0];
    if (!inputFile.type.startsWith("image/")) {
      notification({
        type: NotificationType.ERROR,
        message: "Input media type not allowed",
      });
      return;
    }
    setAvatarInputFile(inputFile);
  };

  return (
    <>
      <RightMainContainer>
        <Loader
          show={
            addingMember ||
            deletingMember ||
            updatingMember ||
            addingUserToWP ||
            uploadingFile
          }
          loader="block"
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() => {
                navigate(
                  backurl
                    ? backurl
                    : `${pathname.split("/").slice(0, -1).join("/")}${
                        web ? `?web=${web}` : ""
                      }`
                );
              }}
            />
            {loadingDefaultMembers || loadingSiteUsers || loadingMembers ? (
              <LoadingCard className="h-6 w-40" />
            ) : (
              <PrimaryHeading>
                {isEqual(id, "add")
                  ? `Add ${isSiteModule ? "User" : USERTYPE[modulePath]}`
                  : inputValue.name + " " + inputValue.last_name}
              </PrimaryHeading>
            )}
          </IconWrapper>
          {(app_users_add_new_users || app_users_edit_users) &&
          (isEqual(id, "add") ? !isLimitReached : true) ? (
            <MainButton
              onClick={handleSubmit}
              disabled={
                isLoading ||
                loadingDefaultMembers ||
                loadingSiteUsers ||
                loadingMembers
              }
            >
              <SaveIcon />
              Save Changes
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <PrimaryHeading className="text-17 mb-2">WordPress</PrimaryHeading>
          <InputField
            label="First Name"
            placeholder="User First Name Here"
            value={inputValue.name}
            setValue={(val) => handleInputChange("name", val)}
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
          />
          <InputField
            label="Last Name"
            placeholder="User Last Name Here"
            value={inputValue.last_name}
            setValue={(val) => handleInputChange("last_name", val)}
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
          />
          <InputField
            label="Email Address"
            placeholder="User Email Address Here"
            value={inputValue.email}
            setValue={(val) => handleInputChange("email", val)}
            type="email"
            disabled={!isEqual(id, "add")}
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
          />
          <div>
            <SecondaryHeading className="text-14">User Photo</SecondaryHeading>
            <div className="mt-2 mb-2 flex items-center gap-8">
              <img
                src={
                  avatarInputFile
                    ? URL.createObjectURL(avatarInputFile)
                    : inputValue.user_avatar
                    ? inputValue.user_avatar
                    : userPic
                }
                className="user_avatar"
              />
              <MainButton
                onClick={() => {
                  userAvatarFileInput.current.value = null;
                  userAvatarFileInput.current.click();
                }}
              >
                Change
              </MainButton>
              <input
                type="file"
                hidden
                ref={userAvatarFileInput}
                onChange={handleUploadAvatar}
                accept="image/*"
              />
            </div>
          </div>
          <SelectField
            label="Default WordPress Role"
            placeholder="Default WordPress Role"
            value={inputValue.wp_user_role}
            setValue={(val) => {
              setInputValue({
                ...inputValue,
                wp_user_role: val,
              });
            }}
            fieldLabel={inputValue.wp_user_role}
          >
            {["administrator", "editor", "author", "subscriber"].map((role) => (
              <option value={role} className="capitalize">
                {role}
              </option>
            ))}
          </SelectField>
          <SelectField
            label="WP SiteLauncher User Type"
            placeholder="WP SiteLauncher User Type"
            value={inputValue.user_type}
            setValue={(val) => {
              setInputValue({
                ...inputValue,
                user_type: val,
                user_role:
                  userRoles[val][Object.keys(userRoles[val])[0]]?.id || "",
              });
            }}
            disabled={isEqual(inputValue.is_owner, "1")}
            fieldLabel={inputValue.role?.user_role}
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
          >
            {objectValues(USERTYPE)
              .filter((role) => !isEqual(role, USERTYPE.OWNER))
              .map((role) => (
                <option value={role}>{role}</option>
              ))}
          </SelectField>
          <SelectField
            label="WP SiteLauncher Role"
            placeholder="WP SiteLauncher Role"
            value={inputValue.user_role}
            setValue={(val) => {
              setInputValue({ ...inputValue, user_role: val });
            }}
            disabled={isEqual(inputValue.is_owner, "1")}
            fieldLabel={
              inputValue?.role?.role_title ?? inputValue?.roles?.role_title
            }
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
            secondaryLabel={
              Object.values(userRoles?.[inputValue.user_type] ?? {})?.find(
                (ele) => isEqual(ele.id, inputValue.user_role)
              )
                ? ""
                : "Library"
            }
          >
            {Object.keys(userRoles?.[inputValue.user_type] ?? {})?.map(
              (role) => {
                return (
                  <option
                    value={userRoles[inputValue.user_type][role].id}
                    secondaryLabel={
                      isEqual(
                        1,
                        userRoles[inputValue.user_type][role].website_id
                      )
                        ? "Site Role"
                        : ""
                    }
                  >
                    {role}
                  </option>
                );
              }
            )}
          </SelectField>
          <SelectField
            label="Associated Company"
            placeholder="None"
            value={inputValue.user_associated_company}
            setValue={(val) => {
              setInputValue({ ...inputValue, user_associated_company: val });
            }}
            isLoading={
              loadingDefaultMembers || loadingSiteUsers || loadingMembers
            }
          >
            {companies.map((company) => (
              <option value={company.id}>{company.company_name}</option>
            ))}
          </SelectField>
          {!isEqual(id, "add") &&
          !isSiteModule &&
          app_users_delete_users &&
          !isEqual(inputValue.is_owner, 1) ? (
            <DeleteButton
              type="button"
              deleteMessage={`You are about to delete this ${USERTYPE[modulePath]} from your account. This action cannot be undone! All related settings and data will be permanently removed. Are you sure you want to do this?`}
              deleteTitle={`Delete This ${USERTYPE[modulePath]}?`}
              deleteButtonText="Confirm Delete"
              className="mt-3"
              style={{ left: "0" }}
              position="top"
              title={`Delete ${USERTYPE[modulePath]}`}
              message={`If you no longer need this ${USERTYPE[modulePath]}, you can choose to delete it from your account. This will permanently remove the ${USERTYPE[modulePath]} and all of its associated data.`}
              buttonText={`Delete ${USERTYPE[modulePath]}`}
              onDelete={() => {
                if (parse(inputValue.website_id)?.length > 0) {
                  notification({
                    type: "error",
                    message: "This user is associated with a website.",
                  });
                } else {
                  deleteMember({ user_id: id, added_by: inputValue.added_by });
                }
              }}
            />
          ) : (
            ""
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
