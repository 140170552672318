import { Link, useParams } from "react-router-dom";
import {
  RightMainContainer,
  HeaderContainer,
  PrimaryHeading,
  MainButton,
  BodyContainer,
  ListIconWrapper,
  ListCard,
  LoadingListCards,
  TemplateNotFound,
  InputField,
  FiltersList,
} from "commonUI";
import { AddIcon } from "allIcons";
import userPic from "assets/pic.png";
import { useMembers } from "api/usersModule/members";
import { useRoles } from "api/usersModule/roles";
import { isEqual, search } from "helpers";
import { USERTYPE } from "usersModule/enums";
import { useDeferredValue, useEffect, useState } from "react";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { useUserLimitReached } from "hooks/plan";
import { useUserPermissions } from "hooks/user";

export default function Members() {
  const { "*": path } = useParams();
  const modulePath = path.split("/")[0];

  const { data: members, isLoading: loadingMembers } = useMembers();
  const { data: roles, isLoading: loadingRoles } = useRoles();
  const { isLoading, isLimitReached } = useUserLimitReached();
  const { app_users_add_new_users, app_users_edit_users } =
    useUserPermissions();

  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setSearchedData(
      members.filter((_) => {
        const isUserRelated =
          isEqual(USERTYPE[modulePath], _.user_type) &&
          (search(`${_.name} ${_.last_name}`, defferedSearch) ||
            search(_.email, defferedSearch));
        if (!isEqual(_.is_owner, 1)) {
          return isUserRelated;
        }
        return modulePath === "team-members" && isEqual(_.is_owner, 1);
      })
    );
  }, [defferedSearch, members, modulePath]);

  useEffect(() => {
    setSearchText("");
  }, [modulePath]);

  const applySorting = (oder, orderBy) => {
    setSearchedData(libraryItemSorting(oder, orderBy, searchedData, "name"));
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchedData(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        members.filter(
          (_) =>
            (isEqual(USERTYPE[modulePath], _.user_type) ||
              (modulePath === "team-members" && isEqual(_.is_owner, 1))) &&
            search(`${_.name} ${_.last_name}`, defferedSearch)
        ),
        "user_role"
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>{USERTYPE[modulePath] + "s"}</PrimaryHeading>
          {isLoading || isLimitReached || !app_users_add_new_users ? (
            ""
          ) : (
            <Link to={"/users/" + modulePath + "/add"}>
              <MainButton>
                <AddIcon />
                Add
              </MainButton>
            </Link>
          )}
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon
            filter
            value={searchText}
            setValue={(value) => setSearchText(value)}
            applySorting={applySorting}
            showTags={false}
            filterList={
              roles
                ?.filter((_) => isEqual(_.user_role, USERTYPE[modulePath]))
                .map((_) => ({
                  id: _.id,
                  title: _.role_title,
                  user_role: _.id,
                })) ?? []
            }
            filterData={filterData}
            filterTitle={"User Role"}
            filterAddTitle={"Add Role"}
            sortOptions={[
              {
                label: "Alphabetical By Title",
                value: "alphabetical-by-title",
              },
              {
                label: "Date Added",
                value: "date-added",
              },
            ]}
            onApplyFilter={applyFilter}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingMembers || loadingRoles ? (
            <LoadingListCards />
          ) : searchedData.length === 0 ? (
            <TemplateNotFound
              label={USERTYPE[modulePath]}
              linkLabel={USERTYPE[modulePath]}
              link={
                isLimitReached || !app_users_add_new_users
                  ? ""
                  : "/users/" + modulePath + "/add"
              }
            />
          ) : (
            searchedData.map((item, index) => {
              return (
                <ListCard
                  key={index}
                  data={`${item.name} ${item.last_name}`}
                  link={
                    app_users_edit_users
                      ? `/users/${modulePath}/${item.id}`
                      : ""
                  }
                  secondaryData={
                    roles.find((_) => isEqual(_.id, item.user_role))
                      ?.role_title ?? ""
                  }
                  strictLink
                  editIcon={app_users_edit_users}
                  deleteIcon={true}
                  icon={
                    <ListIconWrapper className="bg-white">
                      <img src={item.user_avatar ?? userPic} className="avatar" />
                    </ListIconWrapper>
                  }
                />
              );
            })
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
