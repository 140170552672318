import { useEffect, useState } from "react";
import {
  PreviewCard,
  MainButton,
  ColorPicker,
  RightMainContainer,
  BodyContainer,
  HeaderContainer,
  PrimaryHeading,
  InputField,
  Loader,
  SecondaryHeading,
  ToggleCard,
  SelectField,
  Divider,
} from "commonUI";
import { SaveIcon } from "allIcons";
import { useEnvironments, useUpdateEnvironment } from "hooks/environments";
import { useSearch, parse } from "helpers";
import { useUserPermissions } from "hooks/user";

export const EnvironmentsComponent = ({
  color,
  inputLabel,
  title,
  onColorChange,
  onLabelChange,
}) => {
  return (
    <>
      <div className="flex flex-col ">
        <PrimaryHeading className="font-size-17">{title}</PrimaryHeading>
        <div className="">
          <PreviewCard
            label={`${title} Preview`}
            color={color}
            title={inputLabel}
            description="This is a preview of the environment indicator badge that will show in the site admin toolbar."
            textStyle={{ color: "white" }}
            buttonStyle={{ backgroundColor: "#1D2327" }}
          />
        </div>
        <div className="mt-2">
          <InputField
            label={`${title} Label`}
            value={inputLabel}
            setValue={onLabelChange}
            description={`Write a custom text label for the ${title} environment indicator badge.`}
          />
        </div>
        <div className="">
          <InputField
            label={`${title} Color`}
            placeholder={`Write ${title} Color Here`}
            value={color}
            setValue={onColorChange}
            description={`Choose a color for the dot in the ${title} environment indicator badge.`}
          />
          <div className="mt-3 mb-4">
            <ColorPicker value={color} setValue={onColorChange} />
          </div>
        </div>
      </div>
    </>
  );
};

export default function Environments() {
  const { web } = useSearch();
  const { data, isLoading } = useEnvironments();
  const { mutate: updateEnvironment, isLoading: isUpdating } =
    useUpdateEnvironment();
  const [loadingData, setLoadingData] = useState(true);
  const { edit_item_details_in_site_template } = useUserPermissions();

  const [inputValues, setInputValues] = useState({
    production_label: "Production",
    production_color: "#00D263",

    staging_label: "Staging",
    staging_color: "#9958EB",

    development_label: "Development",
    development_color: "#F87904",

    show_environment_badge: true,
    override_the_default_environment: "Default Environment",
  });

  useEffect(() => {
    if (!data || isLoading) return;

    const environment = data?.find((item) => item.website_id === (web || null));
    if (!environment) return;
    setInputValues(
      parse(environment?.environments_settings || "") || inputValues
    );
    setLoadingData(false);
  }, [data, isLoading]);

  const handleChangeInput = (key, value) => {
    setInputValues((pre) => ({ ...pre, [key]: value }));
  };

  const handleSaveChanges = () => {
    updateEnvironment({
      website_id: web || null,
      data: {
        ...inputValues,
        show_environment_badge: inputValues?.show_environment_badge ?? false,
        override_the_default_environment:
          inputValues?.override_the_default_environment ??
          "Default Environment",
      },
    });
  };

  // const renderSelectedEnvironment = () => {
  //   switch (inputValues.override_the_default_environment) {
  //     case "Production":
  //       return (
  //         <>
  //           <EnvironmentsComponent
  //             color={inputValues.production_color}
  //             inputLabel={inputValues.production_label}
  //             title="Production"
  //             onColorChange={(value) =>
  //               handleChangeInput("production_color", value)
  //             }
  //             onLabelChange={(value) =>
  //               handleChangeInput("production_label", value)
  //             }
  //           />
  //         </>
  //       );
  //     case "Staging":
  //       return (
  //         <>
  //           <div className="mt-3 mb-3">
  //             <EnvironmentsComponent
  //               color={inputValues.staging_color}
  //               inputLabel={inputValues.staging_label}
  //               title="Staging"
  //               onColorChange={(value) =>
  //                 handleChangeInput("staging_color", value)
  //               }
  //               onLabelChange={(value) =>
  //                 handleChangeInput("staging_label", value)
  //               }
  //             />
  //           </div>
  //         </>
  //       );
  //     case "Development":
  //       return (
  //         <>
  //           <div className="mt-3 mb-3">
  //             <EnvironmentsComponent
  //               color={inputValues.development_color}
  //               inputLabel={inputValues.development_label}
  //               title="Development"
  //               onColorChange={(value) =>
  //                 handleChangeInput("development_color", value)
  //               }
  //               onLabelChange={(value) =>
  //                 handleChangeInput("development_label", value)
  //               }
  //             />
  //           </div>
  //         </>
  //       );
  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <>
      <RightMainContainer>
        <Loader show={isUpdating} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Environments</PrimaryHeading>
          {edit_item_details_in_site_template ? (
            <MainButton
              onClick={handleSaveChanges}
              disabled={isLoading || isUpdating}
            >
              <SaveIcon />
              Save Changes
            </MainButton>
          ) : (
            ""
          )}
        </HeaderContainer>
        <BodyContainer>
          <SecondaryHeading>
            Show Environment Badge In Admin Bar
          </SecondaryHeading>
          <p className="body-text text-11 leading-4 text-placeholder-gray text-justify">
            When working in WordPress, you may have different environments for
            one site such as production (live), staging or development. Each of
            these environments are for different purposes, but they look exactly
            the same! This can lead to accidental changes and errors on the
            wrong site, which can have crucial impacts. To help solve this
            problem, this setting provides a visual reminder that is always
            visible in the WordPress admin bar to offer website administrators
            the visual reminder of where exactly they are working. This simple
            feature reduces the risk of errors and enhancing workflow
            efficiency, especially when managing multiple sites or collaborating
            with a team. Enabling this setting will add a new badge with colored
            dot and text in the top right side of the WordPress admin bar to
            indicate the current WordPress environment. Each badge will appear
            automatically based on the current environment. You can adjust the
            name and color for each environment with the provided settings.
          </p>
          <ToggleCard
            value={inputValues.show_environment_badge}
            setValue={(value) =>
              handleChangeInput("show_environment_badge", value)
            }
            isLoading={isLoading || loadingData}
          />
          <SecondaryHeading>Override The Default Environment</SecondaryHeading>
          <p className="body-text text-11 leading-4 text-placeholder-gray text-justify">
            Some hosting providers do not properly set the WordPress environment
            in the wp-config.php file. This means our setting cannot provide the
            badge indicator, since the source code is not available. In this
            situation, you may choose an option from this dropdown setting to
            manually set the environment. Just keep in mind if you migrate the
            site or move environments, you would need to update this setting
            accordingly.
          </p>
          <SelectField
            value={inputValues.override_the_default_environment}
            setValue={(value) =>
              handleChangeInput("override_the_default_environment", value)
            }
            isLoading={isLoading}
          >
            <option value="Default Environment">Default Environment</option>
            <option value="Production">Production</option>
            <option value="Staging">Staging</option>
            <option value="Development">Development</option>
          </SelectField>
          <Divider height={2} className="mt-3 mb-3" /> 
          {/* {renderSelectedEnvironment()} */}
          <EnvironmentsComponent
            color={inputValues.production_color}
            inputLabel={inputValues.production_label}
            title="Production"
            onColorChange={(value) =>
              handleChangeInput("production_color", value)
            }
            onLabelChange={(value) =>
              handleChangeInput("production_label", value)
            }
          />
          <Divider height={2} className="mt-1 mb-3"/> 
          <div>
            <EnvironmentsComponent
              color={inputValues.staging_color}
              inputLabel={inputValues.staging_label}
              title="Staging"
              onColorChange={(value) =>
                handleChangeInput("staging_color", value)
              }
              onLabelChange={(value) =>
                handleChangeInput("staging_label", value)
              }
            />
          </div>
          <Divider height={2} className="mt-1 mb-3" /> 
          <div>
            <EnvironmentsComponent
              color={inputValues.development_color}
              inputLabel={inputValues.development_label}
              title="Development"
              onColorChange={(value) =>
                handleChangeInput("development_color", value)
              }
              onLabelChange={(value) =>
                handleChangeInput("development_label", value)
              }
            />
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
