import { useEffect, useState } from "react";
import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  AddComponent,
  MainButton,
  Loader,
  DropDownCard,
  DeleteButton,
  PopOver,
} from "commonUI";
import { useParams } from "react-router-dom";
import {
  useDeleteTemplateTutorial,
  useAddTemplateTutorial,
  useUpdateTemplateTutorial,
  useTemplateTutorials,
} from "api/templateLibrary/tutorials";
import { useNotification } from "notification";
import { useTags } from "api/siteSettings/Tags";
import { getUserSavePermission, parse, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";

export default function AddEditTutorial() {
  const { id } = useParams();
  const { navigate } = useUtilities();
  const notification = useNotification();
  const {
    data: tutorialsList,
    isLoading: tutorialIsLoading,
    error,
  } = useTemplateTutorials();
  const { data: tagsList } = useTags();

  const [tutorialInputs, setTutorialInputs] = useState({
    title: "",
    video_link: "",
    message: "",
    tags: [],
  });
  const [openTags, setOpenTags] = useState(false);
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    if (tutorialIsLoading) return;
    if (id !== "add") {
      const tutorial = tutorialsList.filter(
        (item) => Number(item.id) === Number(id)
      )[0];
      if (tutorial) {
        setTutorialInputs({
          title: tutorial.title,
          video_link: tutorial?.[
            tutorial.video_link ? "video_link" : "video_url"
          ]?.includes('"')
            ? parse(tutorial[tutorial.video_link ? "video_link" : "video_url"])
            : tutorial[tutorial.video_link ? "video_link" : "video_url"],
          message: tutorial.message,
          tags: parse(tutorial.tags),
        });
      }
    }
  }, [id, tutorialsList]);

  const addMutation = useAddTemplateTutorial(
    false,
    "/template-library/tutorials/"
  );
  const editMutation = useUpdateTemplateTutorial();

  const deleteMutation = useDeleteTemplateTutorial(
    "/template-library/tutorials"
  );

  const handleSaveTutorial = () => {
    if (!tutorialInputs.title) {
      notification({
        type: "error",
        message: "Please enter title",
      });
      return;
    }

    if (tutorialInputs.video_link) {
      const isValidUrl = tutorialInputs.video_link.match(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
      );
      if (!isValidUrl) {
        notification({
          type: "error",
          message: "Please enter valid video url",
        });
        return;
      }
    }
    if (id === "add") {
      addMutation.mutate(tutorialInputs);
    } else {
      editMutation.mutate({
        id,
        ...tutorialInputs,
      });
    }
  };

  return (
    <>
      <RightMainContainer>
        {addMutation.isLoading ||
        deleteMutation.isLoading ||
        editMutation.isLoading ? (
          <Loader loader="block" />
        ) : (
          ""
        )}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() => navigate("/template-library/tutorials")}
            />
            <PrimaryHeading className="clamp-text">
              {id !== "add"
                ? tutorialInputs.title ?? "Add Tutorial"
                : "Add Tutorial"}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deleteMutation.mutate(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={handleSaveTutorial}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={tutorialInputs.title}
            setValue={(value) =>
              setTutorialInputs((pre) => ({ ...pre, title: value }))
            }
          />
          <InputField
            label="Video URL"
            placeholder="Write Video URL Here"
            labelClass="font-semibold"
            value={tutorialInputs.video_link}
            setValue={(value) =>
              setTutorialInputs((pre) => ({ ...pre, video_link: value }))
            }
          />
          <TextEditor
            label="Body"
            editorClass="mt-2"
            height={150}
            value={tutorialInputs.message}
            reRender={
              id === "add" ? false : tutorialInputs.message ? true : false
            }
            setValue={(value) => {
              setTutorialInputs((pre) => ({ ...pre, message: value }));
            }}
          />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {tutorialInputs.tags.map((tag) => (
                <PreviewCard
                  color={tag.color}
                  title={tag.text || tag.title}
                  icon={true}
                  className="m-0"
                  onCross={() => {
                    setTutorialInputs((pre) => ({
                      ...pre,
                      tags: pre.tags.filter((item) => item.id !== tag.id),
                    }));
                  }}
                />
              ))}
            </div>
          </div>
          <AddComponent onClick={() => setOpenTags(true)}>
            <PopOver
              open={openTags}
              onClose={() => setOpenTags(false)}
              style={{
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "calc(100% + 15px)",
              }}
              toolTip={{ vertical: "bottom", horizontal: "center" }}
            >
              <DropDownCard
                title="Tags"
                listStyle={true}
                className="relative-important"
                selectedValues={tutorialInputs.tags.map((item) => item.id)}
                list={tagsList.map((ele) => ({
                  ...ele,
                  index: ele.id,
                  text: ele.tag_title,
                }))}
                onSelect={(item) => {
                  if (
                    tutorialInputs.tags.find(
                      (i) => Number(i.id) === Number(item.id)
                    )
                  ) {
                    return;
                  }
                  setTutorialInputs((pre) => ({
                    ...pre,
                    tags: [
                      ...pre.tags,
                      {
                        text: item.tag_title,
                        id: item.id,
                        color: item.color,
                      },
                    ],
                  }));
                }}
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
