import { BackArrow, SaveIcon } from "allIcons";
import {
  BodyContainer,
  HeaderContainer,
  IconWrapper,
  InputField,
  PrimaryHeading,
  RightMainContainer,
  MainButton,
  SecondaryHeading,
  AddComponent,
  ListCard,
  ListIconWrapper,
  PopOver,
  AddFromTemplateLibrary,
  Loader,
  DeleteButton,
  Divider,
} from "commonUI";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import userPic from "assets/pic.png";
import { isEmpty, isEqual, parse, useUtilities } from "helpers";
import {
  useAddCompany,
  useCompanies,
  useDeleteCompany,
  useUpdateCompany,
} from "api/usersModule/companies";
import {
  useDefaultMembers,
  useMembers,
  useSiteRelateUsers,
} from "api/usersModule/members";
import { updateform } from "redux/reducers/usersModule/companies";

export default function AddEditCompany() {
  const {
    notification,
    navigate,
    dispatch,
    companies: { form },
  } = useUtilities();

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const { id } = useParams();
  const [inputValues, setInputValues] = useState(
    isEmpty(form) || isEqual(id, "add") || !isEqual(form.id, id)
      ? {
          company_name: "",
          company_website: "",
          company_phone: "",
          company_email: "",
          company_address1: "",
          company_address2: "",
          company_country: "",
          company_city: "",
          company_zip_code: "",
          company_state: "",
          company_billing_address1: "",
          company_billing_address2: "",
          company_billing_country: "",
          company_billing_city: "",
          company_billing_zip_code: "",
          company_billing_state: "",
          users: [],
        }
      : form
  );
  function handleInputChange(key, value) {
    setInputValues({
      ...inputValues,
      [key]: value,
    });
  }

  const { data: companies, isLoading, error } = useCompanies();
  const { mutate: addCompany, isLoading: addingCompany } =
    useAddCompany("/users/companies/");
  const { mutate: deleteCompany, isLoading: deletingComapny } =
    useDeleteCompany("/users/companies");
  const { mutate: updateCompany, isLoading: updatingCompany } =
    useUpdateCompany();
  const { data: members, isLoading: loadingMembers } = useMembers();
  const { data: defaultMembers, isLoading: loadingDefaultMembers } =
    useDefaultMembers();
  const { data: siteUsers, isLoading: loadingSiteUsers } = useSiteRelateUsers();

  const usersList = useMemo(() => {
    if (
      loadingMembers ||
      loadingDefaultMembers ||
      loadingSiteUsers ||
      !members ||
      !defaultMembers ||
      !siteUsers
    )
      return {};
    return Object.fromEntries(
      [...members, ...defaultMembers, ...siteUsers].map((value) => [
        value.id,
        value,
      ])
    );
  }, [
    members,
    defaultMembers,
    siteUsers,
    loadingMembers,
    loadingDefaultMembers,
    loadingSiteUsers,
  ]);

  useEffect(() => {
    if (
      isLoading ||
      !companies ||
      isEqual(id, "add") ||
      !isEmpty(form) ||
      loadingMembers ||
      loadingDefaultMembers ||
      loadingSiteUsers
    )
      return;
    const company = companies.find((_) => isEqual(_.id, id));
    if (!company) return;
    setInputValues({
      ...company,
      users: parse(company.users).map((_) => ({
        ..._,
        website_id: parse(_.website_id),
        ...(usersList?.[_.id] ?? {}),
      })),
    });
  }, [
    isLoading,
    companies,
    id,
    form,
    usersList,
    loadingMembers,
    loadingDefaultMembers,
    loadingSiteUsers,
  ]);

  function handleSaveChanges() {
    if (inputValues.company_name === "") {
      notification({
        type: "error",
        message: "Please enter company name",
      });
      return;
    }
    if (
      inputValues.company_website &&
      !/^(http|https):\/\/[^ "]+$/.test(inputValues.company_website)
    ) {
      notification({
        type: "error",
        message: "Please enter valid website",
      });
      return;
    }
    if (
      inputValues.company_email &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValues.company_email)
    ) {
      notification({
        type: "error",
        message: "Please enter valid email",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addCompany({
        ...inputValues,
        users: inputValues.users.map((_) => ({ id: _.id })),
        removeUsers: [],
      });
    } else {
      const company = companies.find((_) => isEqual(_.id, id));
      updateCompany({
        ...inputValues,
        company_id: id,
        users: inputValues.users.map((_) => ({ id: _.id })),
        removeUsers: parse(company.users)
          .filter(
            (_) => !inputValues.users.find((ele) => isEqual(ele.id, _.id))
          )
          .map((_) => ({ id: _.id })),
      });
    }
  }
  return (
    <>
      <RightMainContainer>
        <Loader
          show={addingCompany || updatingCompany || deletingComapny}
          loader="block"
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/users/companies")} />
            <PrimaryHeading>
              {isEqual(id, "add") ? "Add Company" : inputValues.company_name}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={!isEqual(id, "add")}
              onDelete={() => deleteCompany(id)}
              deleteMessage="You are about to delete Company from your account. This
            action cannot be undone! All settings related to the Company will permanently removed. Do you wish to
            proceed and delete the Company?"
              style={{ right: "-10px" }}
            />
            <MainButton onClick={handleSaveChanges}>
              <SaveIcon />
              Save Changes
            </MainButton>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <div>
            <PrimaryHeading className="text-17 mb-2">
              Company Details
            </PrimaryHeading>
            <InputField
              label="Name"
              placeholder="Company Name Here"
              className="gap-0 mb-1"
              value={inputValues.company_name}
              setValue={(value) => handleInputChange("company_name", value)}
            />
            <InputField
              label="Website"
              placeholder="Company Website Domain Here"
              className="gap-0 mb-1"
              value={inputValues.company_website}
              setValue={(value) => handleInputChange("company_website", value)}
            />
            <InputField
              type="number"
              label="Phone"
              placeholder="Company Phone Number Here"
              className="gap-0 mb-1"
              value={inputValues.company_phone}
              setValue={(value) => handleInputChange("company_phone", value)}
            />
            <InputField
              label="Email Address"
              placeholder="Company Email Address Here"
              className="gap-0 mb-1"
              value={inputValues.company_email}
              setValue={(value) => handleInputChange("company_email", value)}
            />
          </div>
          <Divider height={2} className="mt-3 mb-3" />
          <div>
            <PrimaryHeading className="text-17 mb-2">
              Company Address
            </PrimaryHeading>
            <InputField
              label="Address Line 1"
              placeholder="Company Address Line 1 Here"
              className="gap-0 mb-1"
              value={inputValues.company_address1}
              setValue={(value) => handleInputChange("company_address1", value)}
            />
            <InputField
              label="Address Line 2"
              placeholder="Company Address Line 2 Here"
              className="gap-0 mb-1"
              value={inputValues.company_address2}
              setValue={(value) => handleInputChange("company_address2", value)}
            />
            <InputField
              label="Country"
              placeholder="Company Country Here"
              className="gap-0 mb-1"
              value={inputValues.company_country}
              setValue={(value) => handleInputChange("company_country", value)}
            />
            <InputField
              label="State/Province"
              placeholder="Company State/Province Here"
              className="gap-0 mb-1"
              value={inputValues.company_state}
              setValue={(value) => handleInputChange("company_state", value)}
            />
            <InputField
              label="City"
              placeholder="Company City Here"
              className="gap-0 mb-1"
              value={inputValues.company_city}
              setValue={(value) => handleInputChange("company_city", value)}
            />
            <InputField
              label="Zip/Postal Code"
              placeholder="Company Zip/Postal Code Here"
              className="gap-0 mb-1"
              value={inputValues.company_zip_code}
              setValue={(value) => handleInputChange("company_zip_code", value)}
            />
          </div>
          <Divider height={2} className="mt-3 mb-3" />
          <div>
            <PrimaryHeading className="text-17 mb-2">
              Company Billing Address
            </PrimaryHeading>
            <InputField
              label="Address Line 1"
              placeholder="Company Billing Address Line 1 Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_address1}
              setValue={(value) =>
                handleInputChange("company_billing_address1", value)
              }
            />
            <InputField
              label="Address Line 2"
              placeholder="Company Billing Address Line 2 Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_address2}
              setValue={(value) =>
                handleInputChange("company_billing_address2", value)
              }
            />
            <InputField
              label="Country"
              placeholder="Company Billing Country Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_country}
              setValue={(value) =>
                handleInputChange("company_billing_country", value)
              }
            />
            <InputField
              label="State/Province"
              placeholder="Company Billing State/Province Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_state}
              setValue={(value) =>
                handleInputChange("company_billing_state", value)
              }
            />
            <InputField
              label="City"
              placeholder="Company Billing City Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_city}
              setValue={(value) =>
                handleInputChange("company_billing_city", value)
              }
            />
            <InputField
              label="Zip/Postal Code"
              placeholder="Company Billing Zip/Postal Code Here"
              className="gap-0 mb-1"
              value={inputValues.company_billing_zip_code}
              setValue={(value) =>
                handleInputChange("company_billing_zip_code", value)
              }
            />
          </div>
          <div>
            <SecondaryHeading>Users In Company</SecondaryHeading>
            {inputValues.users.map((_, index) => (
              <ListCard
                key={index}
                data={`${_.name} ${_.last_name}`}
                secondaryData={isEqual(_.is_owner, 1) ? "Owner" : _.user_type}
                icon={
                  <ListIconWrapper className="bg-white">
                    <img src={userPic} className="avatar" alt={_} />
                  </ListIconWrapper>
                }
                onCross={() => {
                  setInputValues({
                    ...inputValues,
                    users: inputValues.users.filter((__, i) => i !== index),
                  });
                }}
                crossIcon={!isEqual(_.is_owner, 1)}
                deleteIcon={true}
                edit
                strictLink
                link={`/users/${
                  _.user_type === "Client" ? "clients" : "team-members"
                }/${_.id}${
                  _.website_id &&
                  Array.isArray(_.website_id) &&
                  _.website_id.length > 0
                    ? _.website_id.length > 1
                      ? `?backurl=/users/companies/${id}`
                      : `?backurl=/users/companies/${id}&web=${_.website_id[0]}`
                    : `?backurl=/users/companies/${id}`
                }`}
                onEditClick={() => {
                  dispatch(updateform({ ...inputValues, id }));
                }}
                showModalonCross
                deleteMessage={`You are about to remove ${_.name} ${_.last_name} from this company. Are you sure you want to proceed?`}
              />
            ))}
            <AddComponent
              title="Add User"
              onClick={() => setOpenAddUserModal(true)}
            >
              <PopOver
                open={openAddUserModal}
                onClose={setOpenAddUserModal}
                style={{
                  top: "auto",
                  bottom: "calc(100% + 15px)",
                  right: "auto",
                }}
                toolTip={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <AddFromTemplateLibrary
                  heading="Add User"
                  isLoading={
                    loadingMembers || loadingDefaultMembers || loadingSiteUsers
                  }
                  data={Object.values(usersList)
                    .filter(
                      (_) =>
                        inputValues.users.findIndex((ele) =>
                          isEqual(ele.id, _.id)
                        ) === -1 || !_.user_associated_company
                    )
                    .map((item) => ({
                      ...item,
                      title: `${item.name} ${item.last_name}`,
                      icon: (
                        <img src={userPic} className="avatar" alt={item.name} />
                      ),
                      iconWrapperClass: "bg-white",
                      onClick: () =>
                        setInputValues({
                          ...inputValues,
                          users: [...inputValues.users, item],
                        }),
                    }))}
                  emptyTemplate={{
                    label: "User",
                    linkLabel: "User",
                    link: "/users/team-members/add",
                  }}
                />
              </PopOver>
            </AddComponent>
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
