import { useEffect, useState } from "react";
import { LoadingCard } from "./LoadingListCards";
import SecondaryHeading from "./SecondaryHeading";
import "./util.css";
import { t } from "i18next";
import PrimaryHeading from "./PrimaryHeading";
import Divider from "./Divider";

export default function ToggleCard({
  value,
  setValue,
  label = "",
  description = "",
  isLoading = false,
  heading = "",
  labelClass = "",
  showBottomLine = false,
  className = "",
}) {
  const [loading, setLoading] = useState(isLoading);
  useEffect(() => {
    if (isLoading) {
      setLoading(true);
    } else {
      setTimeout(() => {
        setLoading(isLoading);
      }, 100);
    }
  }, [isLoading]);
  return (
    <>
      {heading && (
        <PrimaryHeading className="text-17">{heading}</PrimaryHeading>
      )}
      {label && (
        <SecondaryHeading className={`text-14 mt-2 mb-1 ${labelClass}`}>
          {label}
        </SecondaryHeading>
      )}
      {description ? (
        <p className="body-text text-theme-placeholder text-11 m-0">
          {description}
        </p>
      ) : (
        ""
      )}
      {loading ? (
        <LoadingCard className="h-4-5 toggle-card rounded-full" />
      ) : (
        <div
          className={`toggle-card ${
            !value ? "bg-disabled" : "bg-active"
          } ${className}`}
          onClick={() => setValue(!value)}
        >
          <div className="flex flex-row items-center justify-between w-full">
            <h3 className={`yes-text ${!value ? "opacity-0" : "opacity-100"}`}>
              {t("common.toggleInput.yes")}
            </h3>
            <div
              className={`white-move-button 
          ${!value ? "translate-x-0" : "translate-x-full"}
          `}
            ></div>
            <h3 className={`no-text ${value ? "opacity-0" : "opacity-100"}`}>
              {t("common.toggleInput.no")}
            </h3>
          </div>
        </div>
      )}
      {showBottomLine ? <Divider height={2} className="mt-3 mb-3" /> : ""}
    </>
  );
}
