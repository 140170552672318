import { useEffect, useState } from "react";
import { BackArrow, SaveIcon } from "allIcons";
import {
  AddComponent,
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  PreviewCard,
  DeleteButton,
  MainButton,
  SecondaryHeading,
  RightMainContainer,
  PrimaryHeading,
  Loader,
  PopOver,
  DropDownCard,
} from "commonUI";
import { useParams } from "react-router-dom";
import {
  useAddGlossary,
  useDeleteGlossary,
  useGlosssary,
  useUpdateGlossary,
} from "api/templateLibrary/glossary";
import { useTags } from "api/siteSettings/Tags";
import { getUserSavePermission, isEmpty, parse, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";

export default function AddEditGlossary() {
  const { notification, navigate } = useUtilities();
  const { id } = useParams();
  const [inputValues, setInputValues] = useState({
    glossary_title: "",
    glossary_body: "",
    glossary_integration: "",
    tags: [],
  });
  const [openTags, setOpenTags] = useState(false);
  const {
    data: glossary,
    isLoading: glossaryLoading,
    error: glossaryError,
  } = useGlosssary();
  const { mutate: addGlossary, isLoading: isAdding } = useAddGlossary();
  const { mutate: updateGlossary, isLoading: isUpdating } = useUpdateGlossary();
  const { mutate: deleteGlossary, isLoading: isDeleting } = useDeleteGlossary();
  const { data: tags } = useTags();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  useEffect(() => {
    if (id === "add" || glossaryLoading || glossary.length === 0) return;
    const glossaryData = glossary.filter(
      (item) => String(item.id) === String(id)
    )[0];

    if (isEmpty(glossaryData)) {
      navigate("/template-library/glossary");
      return;
    }
    setInputValues({
      glossary_title: glossaryData.glossary_title ?? "",
      glossary_body: glossaryData.glossary_body ?? "",
      glossary_integration: glossaryData.glossary_integration ?? "",
      tags: parse(glossaryData.tags ?? "[]"),
    });
  }, [glossaryLoading, id, glossary]);

  function handleSave() {
    if (inputValues.glossary_title === "") {
      notification({
        type: "error",
        message: "Please enter title",
      });
      return;
    }
    if (id === "add") {
      addGlossary({ ...inputValues });
      return;
    }
    updateGlossary({ ...inputValues, glossary_id: id });
  }

  return (
    <>
      <RightMainContainer>
        {(isAdding || isUpdating || isDeleting) && <Loader loader="block" />}
        <HeaderContainer>
          <IconWrapper>
            <BackArrow onClick={() => navigate("/template-library/glossary")} />
            <PrimaryHeading className="clamp-text">
              {id === "add" ? "Add Glossary" : inputValues.glossary_title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={id !== "add" && delete_templates}
              onDelete={() => deleteGlossary(id)}
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={handleSave}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Title"
            placeholder="Write Title Here"
            value={inputValues.glossary_title}
            setValue={(value) =>
              setInputValues((pre) => ({
                ...pre,
                glossary_title: value,
              }))
            }
          />
          <TextEditor
            label="Body"
            editorClass="mt-2"
            value={inputValues.glossary_body}
            reRender={
              id === "add" ? false : inputValues.glossary_body ? true : false
            }
            setValue={(value) =>
              setInputValues((pre) => ({ ...pre, glossary_body: value }))
            }
          />
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((tag) => (
                <PreviewCard
                  color={tag.color}
                  title={tag.text}
                  icon={true}
                  className="m-0"
                  onCross={() =>
                    setInputValues((pre) => ({
                      ...pre,
                      tags: pre.tags.filter(
                        (_) => String(_.id) !== String(tag.id)
                      ),
                    }))
                  }
                />
              ))}
            </div>
          </div>
          <AddComponent value={openTags} onClick={() => setOpenTags(true)}>
            <PopOver
              open={openTags}
              onClose={() => setOpenTags(false)}
              style={{
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "calc(100% + 15px)",
              }}
              toolTip={{ vertical: "bottom", horizontal: "center" }}
            >
              <DropDownCard
                title="Tags"
                listStyle={true}
                selectedValues={inputValues.tags.map((tag) => tag.id)}
                list={tags?.map((tag) => ({
                  ...tag,
                  text: tag.tag_title,
                  index: tag.id,
                }))}
                onSelect={(value) =>
                  inputValues.tags.filter(
                    (_) => String(_.id) === String(value.id)
                  ).length === 0 &&
                  setInputValues((pre) => ({
                    ...pre,
                    tags: [
                      ...pre.tags,
                      {
                        id: value.id,
                        text: value.tag_title,
                        color: value.color,
                      },
                    ],
                  }))
                }
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
