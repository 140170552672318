import { useState, Children, useDeferredValue, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  PopOver,
  ListIconWrapper,
  ListCard,
  MainButton,
  HeaderContainer,
  PrimaryHeading,
  BodyContainer,
  RightMainContainer,
  Loader,
  LoadingListCards,
  TemplateNotFound,
  InputField,
  FiltersList,
} from "commonUI";
import { AddIcon, PluginIcon, OpenIcon, ImportIcon } from "allIcons";
import {
  usePlugins,
  useDeletePlugin,
  useAddPlugin,
} from "api/templateLibrary/plugins";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";
import { isEqual, parse, search, useUtilities } from "helpers";
import { useUserPermissions } from "hooks/user";
import { useUploadFiles } from "api/util";
import { useTags } from "api/siteSettings/Tags";
import { usePluginList } from "api/templateLibrary/pluginList";

export default function Plugins() {
  const [openPopOver, setOpenPopOver] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { notification } = useUtilities();
  const { data: plugins, isLoading, error } = usePlugins();
  const { mutate: deletePlugin, isLoading: deletePluginLoading } =
    useDeletePlugin();
  const { mutateAsync: uploadFiles, isLoading: uploadingPlugin } =
    useUploadFiles();
  const { mutate: addPlugin, isLoading: addingPlugin } = useAddPlugin();

  const [searchText, setSearchText] = useState("");
  const defferedSearch = useDeferredValue(searchText);
  const [searchResult, setSearchResult] = useState([]);
  const { add_new_templates, delete_templates, edit_templates } =
    useUserPermissions();
  const { data: tags } = useTags();
  const { data: pluginList } = usePluginList();

  useEffect(() => {
    setSearchResult(
      plugins.filter((task) => search(task?.plugin_title ?? "", defferedSearch))
    );
  }, [defferedSearch, plugins]);

  const applySorting = (order, orderBy) => {
    setSearchResult((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "plugin_title")
    );
  };

  const onUploadPlugin = (e) => {
    const file = e.target.files[0];
    if (file.type.includes("zip")) {
      setOpenPopOver(false);
      uploadFiles({ product_logo: file }).then((res) => {
        if (res.Data) {
          addPlugin({
            plugin_title: res["File Name"],
            plugin_slug: res["Data"],
            plugin_type: 1,
            plugin_icon: null,
            added_by: localStorage.getItem("user_id"),
          });
        } else {
          notification({
            type: "error",
            message: "Failed to upload plugin.",
          });
        }
      });
    } else {
      notification({
        type: "error",
        message: "Invalid file type, please upload a .zip file.",
      });
    }
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchResult(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        plugins,
        "plugin_type"
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={addingPlugin || uploadingPlugin} loader="block" />
        <Loader show={deletePluginLoading} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Plugins</PrimaryHeading>
          <div className="relative">
            {add_new_templates ? (
              <MainButton onClick={() => setOpenPopOver(true)}>
                <AddIcon />
                Add
              </MainButton>
            ) : (
              ""
            )}
            <PopOver open={openPopOver} onClose={setOpenPopOver}>
              <div className="list-pop-up-content">
                <Link className="add-pop-up-content" to="add">
                  <div className="add-pop-up-content-icon">
                    <AddIcon />
                  </div>
                  <h3 className="add-pop-up-content-title">
                    From WordPress Repo
                  </h3>
                  <div className="add-pop-up-content-arrow">
                    <OpenIcon />
                  </div>
                </Link>
                <label className="add-pop-up-content" htmlFor="plugin-input">
                  <div className="add-pop-up-content-icon">
                    <ImportIcon />
                  </div>
                  <h3 className="add-pop-up-content-title">
                    Upload Plugin .zip File
                  </h3>
                  <input
                    type="file"
                    hidden
                    id="plugin-input"
                    accept=".zip,.rar,.7zip"
                    onChange={onUploadPlugin}
                  />
                </label>
              </div>
            </PopOver>
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            placeholder="Search"
            searchIcon
            value={searchText}
            setValue={setSearchText}
            applySorting={applySorting}
            onApplyFilter={applyFilter}
            filter
            sort
            filterData={filterData}
            tags={tags ?? []}
            filterTitle="Plugin Source"
            filterList={[
              {
                id: 1,
                title: "From Wordpress Repo",
                plugin_type: 0,
              },
              {
                id: 2,
                title: "Uploaded Plugin .zip File",
                plugin_type: 1,
              },
            ]}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          <div className="">
            {isLoading ? (
              <LoadingListCards />
            ) : error ? (
              <p className="body-text">{error}</p>
            ) : searchResult.length === 0 ? (
              <TemplateNotFound
                label="Plugins"
                linkLabel="Plugin"
                link={add_new_templates ? "add" : ""}
              />
            ) : (
              Children.toArray(
                searchResult.map((plugin, index) => {
                  return (
                    <ListCard
                      data={plugin?.plugin_title}
                      secondaryData={
                        isEqual(plugin.plugin_type, 1) ? "Uploaded" : ""
                      }
                      editIcon={edit_templates}
                      link={
                        edit_templates
                          ? `/template-library/plugins/${plugin.id}`
                          : ""
                      }
                      strictLink
                      key={index}
                      index={index}
                      deleteIcon={!delete_templates}
                      onDelete={() => {
                        if (
                          pluginList.some((_) => {
                            return parse(_.plugin_list).some((__) =>
                              isEqual(__.id, plugin.id)
                            );
                          })
                        )
                          return false;
                        else deletePlugin(plugin.id);
                        return true;
                      }}
                      showDeleteResult={true}
                      deleteResponseMessage="This plugin cannot be removed because it is being used in one or more Plugin Lists. To remove this plugin completely, please remove it from any Plugin Lists first."
                      deleteResponseTitle="Cannot Remove This Plugin"
                      icon={
                        <ListIconWrapper
                          className={plugin.plugin_icon ? "bg-white" : ""}
                        >
                          {plugin.plugin_icon ? (
                            <img
                              src={plugin.plugin_icon}
                              alt={plugin.plugin_title}
                              className="avatar"
                            />
                          ) : (
                            <PluginIcon />
                          )}
                        </ListIconWrapper>
                      }
                      deleteTitle="Delete This Template?"
                      deleteButtonText="Confirm Delete"
                      deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
                    />
                  );
                })
              )
            )}
          </div>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
