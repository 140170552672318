import { useState, Children, useEffect } from "react";
import { BackArrow, SaveIcon, TaskIcon } from "allIcons";
import {
  BodyContainer,
  TextEditor,
  HeaderContainer,
  IconWrapper,
  InputField,
  ListCard,
  PreviewCard,
  PrimaryHeading,
  RightMainContainer,
  SecondaryHeading,
  ListIconWrapper,
  MainButton,
  AddComponent,
  DeleteButton,
  PopOver,
  AddFromTemplateLibrary,
  DropDownCard,
  Loader,
} from "commonUI";
import { useParams } from "react-router-dom";
import { getUserSavePermission, isEqual, parse, useUtilities } from "helpers";
import { useTasks } from "api/templateLibrary/tasks";
import { useTags } from "api/siteSettings/Tags";
import { NotificationType } from "enums";
import {
  useAddChecklistTemplate,
  useCheckListTemplates,
  useUpdateChecklistTemplate,
  useDeleteChecklistTemplate,
} from "api/templateLibrary/checklists";
import { useUserPermissions, useUserRole } from "hooks/user";

export default function AddEditChecklist() {
  const { id } = useParams();
  const { notification, navigate } = useUtilities();
  const { data: tasks, isLoading: tasksLoading } = useTasks();
  const { data: tags, isLoading: tagsLoading } = useTags();
  const { mutateAsync: addChecklist, isLoading: addingChecklist } =
    useAddChecklistTemplate("/template-library/checklists/");
  const { data: checklists, isLoading: loadingChecklists } =
    useCheckListTemplates();
  const { mutateAsync: updateChecklist, isLoading: updatingChecklist } =
    useUpdateChecklistTemplate();
  const { mutateAsync: deleteChecklist, isLoading: deletingChecklist } =
    useDeleteChecklistTemplate();
  const { add_new_templates, edit_templates, delete_templates } =
    useUserPermissions();

  const { isSuperAdmin } = useUserRole();

  const [popoverStatus, setPopoverStatus] = useState({
    task: false,
    tag: false,
  });

  const [inputValues, setInputValues] = useState({
    assign_to: [],
    due_date: "",
    website_id: null,
    title: "",
    message: "",
    tags: [],
    priority: [],
    tasks: [],
  });

  useEffect(() => {
    if (isEqual(id, "add") || loadingChecklists) {
      return;
    }
    if (checklists.length !== 0) {
      const checklist = checklists.find((item) => isEqual(item.id, id));
      if (checklist) {
        setInputValues({
          ...inputValues,
          ...checklist,
          tasks: parse(checklist.tasks),
          tags: parse(checklist.tags),
        });
      }
    }
  }, [checklists, loadingChecklists]);

  function handleChangeInput(key, value) {
    setInputValues({
      ...inputValues,
      [key]: value,
    });
  }
  function handlePopoverChange(key, value) {
    setPopoverStatus({
      ...popoverStatus,
      [key]: value,
    });
  }

  function hanldeSaveChanges() {
    if (!inputValues.title.trim()) {
      notification({
        type: NotificationType.ERROR,
        message: "Please enter checklist title",
      });
      return;
    }
    if (isEqual(id, "add")) {
      addChecklist(inputValues);
      return;
    } else {
      updateChecklist(inputValues);
      return;
    }
  }

  return (
    <>
      <RightMainContainer>
        <Loader
          loader="block"
          show={addingChecklist || updatingChecklist || deletingChecklist}
        />
        <HeaderContainer>
          <IconWrapper>
            <BackArrow
              onClick={() => navigate("/template-library/checklists")}
            />
            <PrimaryHeading>
              {isEqual(id, "add") ? "Add Checklist" : inputValues.title}
            </PrimaryHeading>
          </IconWrapper>
          <div className="flex items-center justify-center gap-14">
            <DeleteButton
              show={
                !isEqual(id, "add") &&
                !(isEqual(inputValues.system_status, 1) && !isSuperAdmin) &&
                delete_templates
              }
              deleteTitle="Delete This Template?"
              deleteButtonText="Confirm Delete"
              deleteMessage="You are about to delete this template from your account. This action cannot be undone! All template content will be permanently removed. Are you sure you want to do this?"
              onDelete={() => deleteChecklist(id)}
            />
            {getUserSavePermission({
              id,
              addPermission: add_new_templates,
              editPermission: edit_templates,
            }) ? (
              <MainButton onClick={() => hanldeSaveChanges()}>
                <SaveIcon />
                Save Changes
              </MainButton>
            ) : (
              ""
            )}
          </div>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            label="Checklist Title"
            placeholder="Write Checklist Title Here"
            value={inputValues.title}
            setValue={(value) => handleChangeInput("title", value)}
            disabled={isEqual(inputValues.system_status, 1)}
          />
          <TextEditor
            label="Description"
            editorClass="mt-2"
            option={false}
            rows={5}
            value={inputValues.message}
            setValue={(value) => handleChangeInput("message", value)}
          />
          <div className="mt-3">
            <SecondaryHeading>Tasks</SecondaryHeading>
            {Children.toArray(
              inputValues.tasks.map((task, index) => {
                return (
                  <ListCard
                    data={task.title}
                    edit={true}
                    // TODO: update back link
                    link={`/template-library/tasks/${task.id}?backLink=/checklist/add`}
                    strictLink={true}
                    deleteIcon={true}
                    showModalonCross
                    onCross={() => {
                      handleChangeInput(
                        "tasks",
                        inputValues.tasks.filter((_) => !isEqual(_.id, task.id))
                      );
                    }}
                    icon={
                      <ListIconWrapper>
                        <TaskIcon />
                      </ListIconWrapper>
                    }
                    deleteMessage="You are about to remove this item from your template. Are you sure you want to do this?"
                    deleteTitle="Remove This Item?"
                    crossIcon
                  />
                );
              })
            )}
          </div>
          <AddComponent
            title={"Add Task"}
            className="mt-3 mb-3"
            value={popoverStatus.task}
            onClick={() => handlePopoverChange("task", true)}
          >
            <PopOver
              open={popoverStatus.task}
              onClose={() => handlePopoverChange("task", false)}
              style={{
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "calc(100% + 15px)",
              }}
              toolTip={{ vertical: "bottom", horizontal: "center" }}
            >
              <AddFromTemplateLibrary
                heading="Add Task"
                data={tasks
                  .filter(
                    (task) =>
                      !inputValues.tasks.some((item) => item.id === task.id)
                  )
                  .map((task) => ({
                    title: task.title,
                    icon: <TaskIcon />,
                    onClick: () => {
                      handleChangeInput("tasks", [
                        ...inputValues.tasks,
                        {
                          id: task.id,
                          title: task.title,
                          message: task.message ?? task.description,
                        },
                      ]);
                    },
                  }))}
                emptyTemplate={{
                  label: "Task",
                  link: "/template-library/tasks/add",
                  linkLabel: "Task",
                }}
              />
            </PopOver>
          </AddComponent>
          <div className="mt-2">
            <SecondaryHeading>Tags</SecondaryHeading>
            <div className="flex items-center gap-5">
              {inputValues.tags.map((tag, index) => (
                <PreviewCard
                  color={tag.color}
                  title={tag.text}
                  icon={true}
                  className="m-0"
                  onCross={() => {
                    handleChangeInput(
                      "tags",
                      inputValues.tags.filter((_) => !isEqual(_.id, tag.id))
                    );
                  }}
                />
              ))}
            </div>
          </div>
          <AddComponent
            value={popoverStatus.tag}
            onClick={() => handlePopoverChange("tag", true)}
          >
            <PopOver
              open={popoverStatus.tag}
              onClose={() => handlePopoverChange("tag", false)}
              style={{
                top: "auto",
                left: "auto",
                right: "auto",
                bottom: "calc(100% + 15px)",
              }}
              toolTip={{ vertical: "bottom", horizontal: "center" }}
            >
              <DropDownCard
                title="Tags"
                listStyle={true}
                className="relative-important"
                isEmpty={tags.length === 0}
                selectedValues={inputValues.tags.map((item) => item.id)}
                list={tags.map((ele) => ({
                  ...ele,
                  index: ele.id,
                  text: ele.tag_title,
                }))}
                onSelect={(item) => {
                  if (inputValues.tags.find((i) => isEqual(i.id, item.id))) {
                    return;
                  }
                  setInputValues((pre) => ({
                    ...pre,
                    tags: [
                      ...pre.tags,
                      {
                        id: item.id,
                        text: item.tag_title,
                        color: item.color,
                      },
                    ],
                  }));
                }}
              />
            </PopOver>
          </AddComponent>
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
